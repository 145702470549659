import { Box, Button, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import coverAbout from './../../assetss/Header/Rectangle 1205.png'
import { Link } from 'react-router-dom'

const ProductsCatalog = () => {
    return (
        <>
            <Box className="testimonials" sx={{ position: 'relative', padding: '60px 0' }} >
                <div className="overlay" style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: '1' }} ></div>
                <CardMedia component={'img'} src={coverAbout} sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} />
                <Container sx={{ zIndex: '2', position: 'relative' }} >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }} >
                        <Typography variant='h4' sx={{ color: '#F7C42B', fontSize: { md: '4.3rem', xs: '3rem' } }} >كتالوج المنتجات</Typography>
                        <Typography variant='h6' sx={{ color: '#FFF', width: { md: '50%', xs: '98%' }, textAlign: 'center', fontSize: { md: '2rem', xs: '1.5rem' } }} >تقدر الأن تمتع نظرك بمشاهدة أعمالنا السابقة.</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', alignItems: 'center' }} >
                            <Link to={'https://drive.google.com/file/d/12FO12a2yZazTwg5pysFN7nQm_JiXt56o/view?usp=sharing'} target='_blank' >
                                <Button sx={{ background: '#F7C42B', color: '#000', fontSize: '1.5rem', "&:hover": { backgroundColor: '#F7C42B' } }} >بروفايل المصنع</Button>
                            </Link>
                            <Link to={'https://drive.google.com/file/d/125C4ntvOH3UIpXNrHj1PVflqUR31tMi7/view?usp=sharing'} target='_blank' >
                                <Button sx={{ background: '#F7C42B', color: '#000', fontSize: '1.5rem', "&:hover": { backgroundColor: '#F7C42B' } }} >تحميل الكتالوج</Button>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ProductsCatalog
import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import RoomIcon from '@mui/icons-material/Room';
const ContactData = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#253441', p: 3, color: '#FFF', height: '70vh', alignItems: 'flex-start', justifyContent: 'space-around', position: 'relative', borderRadius: '30px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: 'column' }} >
                    <Typography variant='h3' > تواصل معنا عبر </Typography>
                    <Typography variant='h4' >بيانات التواصل معنا</Typography>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <SettingsPhoneIcon sx={{ fontSize: '2rem' }} />
                        <Typography sx={{ fontSize: '1.5rem' }} >+966502501601</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <EmailIcon sx={{ fontSize: '2rem' }} />
                        <Typography sx={{ fontSize: '1.5rem' }} >info@idoorksa.com</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <RoomIcon sx={{ fontSize: '2rem' }} />
                        <Typography sx={{ fontSize: '1.5rem' }} >8152 أبي محمد التاجر, As Sulay، RQYB3784, Riyadh 14322, Saudi Arabia</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                    <Avatar sx={{ height: '100px', width: '100px', background: '#8d959a', color: '#8d959a' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-around', position: 'absolute', top: '85%', left: '10%' }}>
                        <WhatsAppIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <InstagramIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                        <FacebookIcon sx={{ color: '#FFF', fontSize: '2.5rem' }} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ContactData
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './../SliderLogic.css';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';
// import image_1 from '../../assetss/images/37 incredible basement bar design thatll make feel good 21.png';
// import image_2 from '../../assetss/images/Premium Photo _ Burger and french fries on cutboard.png';
// import image_3 from '../../assetss/images/Tuk Tuk Indian Street Food.png';
// import image_4 from '../../assetss/images/Tuk Tuk Indian Street Food.png';
// import image_5 from '../../assetss/images/Tuk Tuk Indian Street Food.png';
const SliderLogic = ({ image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9, image_10, image_11, image_12, image_13, image_14, image_15, image_16, image_17, image_18, image_19, image_20 }) => {
    return (
        <div className="container">
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 5,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                // navigation={{
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                //     clickable: true,
                // }}
                navigation={true}
                modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
                className="swiper_container"
            >
                <SwiperSlide>
                    <a href={image_1} target='_blank' rel='noreferrer'>
                        <img src={image_1} alt="slide_image" />
                    </a>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <a href={image_2} target='_blank' rel='noreferrer'>
                        <img src={image_2} alt="slide_image" />
                    </a>
                </SwiperSlide> */}
                <SwiperSlide>
                    <a href={image_3} target='_blank' rel='noreferrer'>
                        <img src={image_3} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_4} target='_blank' rel='noreferrer'>
                        <img src={image_4} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_5} target='_blank' rel='noreferrer'>
                        <img src={image_5} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_6} target='_blank' rel='noreferrer'>
                        <img src={image_6} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_7} target='_blank' rel='noreferrer'>
                        <img src={image_7} alt="slide_image" />
                    </a>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <a href={image_8} target='_blank' rel='noreferrer'>
                        <img src={image_8} alt="slide_image" />
                    </a>
                </SwiperSlide> */}
                <SwiperSlide>
                    <a href={image_9} target='_blank' rel='noreferrer'>
                        <img src={image_9} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_10} target='_blank' rel='noreferrer'>
                        <img src={image_10} alt="slide_image" />
                    </a>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <a href={image_11} target='_blank' rel='noreferrer'>
                        <img src={image_11} alt="slide_image" />
                    </a>
                </SwiperSlide> */}
                <SwiperSlide>
                    <a href={image_12} target='_blank' rel='noreferrer'>
                        <img src={image_12} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_13} target='_blank' rel='noreferrer'>
                        <img src={image_13} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_14} target='_blank' rel='noreferrer'>
                        <img src={image_14} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_15} target='_blank' rel='noreferrer'>
                        <img src={image_15} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_16} target='_blank' rel='noreferrer'>
                        <img src={image_16} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_17} target='_blank' rel='noreferrer'>
                        <img src={image_17} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_18} target='_blank' rel='noreferrer'>
                        <img src={image_18} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_19} target='_blank' rel='noreferrer'>
                        <img src={image_19} alt="slide_image" />
                    </a>
                </SwiperSlide>
                <SwiperSlide>
                    <a href={image_20} target='_blank' rel='noreferrer'>
                        <img src={image_20} alt="slide_image" />
                    </a>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default SliderLogic
import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import CatalogList from '../components/Catalog/CatalogList'
import door from './../assetss/door 1.png'
// import cardImg from './../assetss/decorating 1.png'
const Catalog = () => {
    return (
        <>
            <Box sx={{ padding: '32px 0', backgroundColor: '#FEF9EA' }}>
                <Box>
                    <Box sx={{ backgroundColor: '#253441', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }} >
                        <Typography variant='h4' sx={{ color: '#F7C42B', textAlign: 'center' }} >الأبواب الداخلية</Typography>
                        <CardMedia component={'img'} src={door} sx={{ width: '50px' }} />
                    </Box>
                    <CatalogList />
                </Box>
                {/* <Box>
                    <Box sx={{ backgroundColor: '#F7C42B', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }} >
                        <Typography variant='h4' sx={{ color: '#253441', textAlign: 'center' }} >الديكورات الداخلية</Typography>
                        <CardMedia component={'img'} src={cardImg} sx={{ width: '50px' }} />
                    </Box>
                    <Box position={'relative'} >
                        <Typography sx={{ width: '100%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                        <CatalogList />
                    </Box>
                    <Box position={'relative'} >
                        <Typography sx={{ width: '100%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                        <CatalogList />
                    </Box>
                    <Box position={'relative'} >
                        <Typography sx={{ width: '100%', height: '100px', position: 'absolute', top: '30%', background: 'rgba(247, 196, 43, 0.50)', left: '0' }} ></Typography>
                        <CatalogList />
                    </Box>
                </Box> */}
            </Box >
        </>
    )
}

export default Catalog
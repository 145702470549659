import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import SotialFooter from './SotialFooter'
import { Link, useNavigate } from 'react-router-dom'
// import logo from './../../assetss/Logo/logo.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './style.css'
const Footer = () => {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ backgroundColor: '#253441', p: 2, position: 'relative' }} >
                <Divider sx={{ borderColor: '#FFF', position: 'absolute', left: 0, top: '50px', width: '100%' }} />
                <Container>
                    <Grid container spacing={{ md: 5, xs: 1 }} >
                        <Grid item md={3} xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: { md: 'flex-start', xs: 'center' }, justifyContent: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F7C42B' }} >
                                    _ من نحن
                                </Typography>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold', color: '#FFF', textAlign: { md: 'right', xs: 'center' } }} >
                                    نحن شركة I’DOOR لصناعة الأبواب عالية الجودة بمختلف أنواعها لتناسب جميع الأذواق مصنعة من مادة wpc " الخشب البلاستيكي"، تتميز بثبات ألوانها وقوتها ضد الصدمات ومضادة للتشقق والماء،نوفرلك أمانك لإنه يبدأ من بابك
                                </Typography>
                                <SotialFooter />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: { md: 'flex-start', xs: 'center' }, justifyContent: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F7C42B' }} >
                                    _ الصفحات
                                </Typography>
                                <ul style={{ listStyle: 'none', color: '#FFF' }} >
                                    <li className='hovLink' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} >_ الرئيسية</li>
                                    <li className='hovLink' style={{ cursor: 'pointer' }} onClick={() => navigate('/catalog')} >_ الكتالوج</li>
                                    <li className='hovLink' style={{ cursor: 'pointer' }} onClick={() => navigate('/contact-us')} >_ تواصل معنا</li>
                                    <li className='hovLink' style={{ cursor: 'pointer' }} onClick={() => navigate('/about-us')} >_ من نحن</li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: { md: 'flex-start', xs: 'center' }, justifyContent: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F7C42B' }} >
                                    _ خدماتنا
                                </Typography>
                                <ul style={{ listStyle: 'none', color: '#FFF' }} >
                                    <li>_ الأبواب الداخلية</li>
                                    <li>_ الديكورات الداخلية</li>
                                    <li>_ مقابض أبواب</li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: { md: 'flex-start', xs: 'center' }, justifyContent: 'center' }} >
                                <Typography variant="h4" sx={{ color: '#F7C42B' }} >
                                    _ مواقعنا
                                </Typography>
                                <div>
                                    <nav class="social_footer">
                                        <ul>
                                            <li><a href="https://maps.app.goo.gl/Y93CWNeWwGE3ZKTt6">
                                                <LocationOnIcon />
                                                فرع الأحساء
                                                {/* <img src={img} alt="icon" style={{ background: '#1976D2' }} /> */}
                                            </a></li>
                                            <li><a href="https://maps.app.goo.gl/fcPhubm1hb9R7FwM7">
                                                <LocationOnIcon />
                                                الفرع الرئيسي
                                                {/* <img src={img} alt="icon" style={{ background: '#F84E51' }} /> */}
                                            </a></li>
                                            <li><a href="https://maps.app.goo.gl/rZLUoZpjrDaGzsJdA">
                                                <LocationOnIcon />
                                                حي الياسمين
                                                {/* <img src={img} alt="icon" style={{ background: '#000' }} /> */}
                                            </a></li>
                                        </ul>
                                    </nav>
                                </div>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.5908107306914!2d46.6828275!3d24.568798599999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2fa7284fc10349%3A0x11e7f2b76802eea1!2z2YXYtdmG2Lkg2KfZiiDYr9mI2LEg2YTZhNij2KjZiNin2Kgg2KfZhNiv2KfYrtmE2YrYqQ!5e0!3m2!1sen!2seg!4v1708243227316!5m2!1sen!2seg" title='IDoor'
                                    width="auto"
                                    height="auto"
                                    style={{ borderRadius: "30px", border: '0', outline: 'none' }} 
                                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: '#F7C42B', textAlign: 'center', fontWeight: 'bold', color: '#FFF', p: 1 }} >
                <Typography variant='h5' sx={{ color: '#253441', fontWeight: 'bold' }} >جميع الحقوق محفوظة لدى شركة I’DOOR</Typography>
                <Typography variant='h6' sx={{ color: '#253441' }} >
                    <Link
                        to="https://revampbrands.com/"
                        style={{
                            color: '#253441',
                            textDecoration: "none",
                            fontSize: 18,
                            fontWeight: "bold",
                        }}
                    >
                        تصميم وتطوير شركة Revamp
                    </Link>
                </Typography>
            </Box>
        </>
    )
}

export default Footer
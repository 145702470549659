import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './ServiceCard.css'
const ServiceCard = ({ imgSrc, title, desc }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2, margin: '0 18px', backgroundColor: '#253441', p: 2, height: '300px', borderRadius: '30px', cursor: 'pointer' }} className='BoxCard' >
            <Box sx={{ width: '100px', height: '100px', borderRadius: '50%', backgroundColor: '#FFF', display: 'flex', boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", alignItems: 'center', justifyContent: 'center' }} >
                <CardMedia component={'img'}
                    src={imgSrc}
                    sx={{ height: '60px', width: '60px', objectFit: 'fill' }}
                />
            </Box>
            <Typography variant='h5' sx={{ color: '#F7C42B', fontSize: '2rem' }} className='box-title' >{title}</Typography>
            <Typography sx={{ color: '#FFF', textAlign: 'center', fontSize: '1.5rem' }} >{desc}</Typography>
        </Box>
    )
}

export default ServiceCard
import { Box, Button, Grid, TextField } from '@mui/material'
import React from 'react'
import './FormLogic.css'
import { useForm } from 'react-hook-form';
import { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { enqueueSnackbar } from "notistack";
const FormLogic = () => {
    const form = useRef()
    const { register, handleSubmit, reset } = useForm();
    const sendEmail = (e) => {
        // e.preventDefault();
        emailjs.sendForm('service_alpm75d', 'template_vzi9bvm', form.current, 'kndNMDE1YyAUbYaCV')
            .then((result) => {
                console.log(result.text);
                enqueueSnackbar("Data sent successfully👍")
                reset()
            }, (error) => {
                console.log(error.text);
                enqueueSnackbar(error, { variant: 'error' });
                reset()
            });
        reset()
    }
    // const onSubmit = data => console.log(data);
    return (
        <>
            <Box>
                <form ref={form} onSubmit={handleSubmit(sendEmail)} mt={1} name="from_name">
                    <Box>
                        <Grid container rowGap={10} >
                            <Grid item md={6} xs={12}>
                                <TextField
                                    // defaultValue={'الاسم الاول'}
                                    name="first_name"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("first_name", { required: true })}
                                    id="standard-basic" label="الاسم الاول" variant="standard"
                                    sx={{ fontSize: '1.5rem', minWidth: '90%' }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="last_name"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("last_name", { required: true })}
                                    // defaultValue={'الاسم الأخير'}
                                    id="standard-basic" label="الاسم الأخير" variant="standard"
                                    sx={{ fontSize: '1.5rem', minWidth: '90%' }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="email"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("email", { required: true })}
                                    // defaultValue={'البريد الالكتروني'}
                                    type='email'
                                    id="standard-basic" label="البريد الالكتروني" variant="standard"
                                    sx={{ fontSize: '1.5rem', minWidth: '90%' }} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    name="phone"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("phone", { required: true })}
                                    // defaultValue={'رقم الهاتف'}
                                    id="standard-basic" label="رقم الهاتف" variant="standard"
                                    sx={{ fontSize: '1.5rem', minWidth: '90%' }} />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    name="message"
                                    // onChange={(e) => setPhone(e.target.value)}
                                    {...register("message", { required: true })}
                                    // defaultValue={'رسالتك'}
                                    id="filled-multiline-flexible"
                                    label="رسالتك"
                                    multiline
                                    maxRows={4}
                                    variant="filled"
                                    sx={{ fontSize: '1.5rem' }}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ textAlign: 'center', mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ color: '#FFF', p: '13px 75px', textAlign: 'center', backgroundColor: '#253441', borderRadius: '8px', fontSize: '1.3rem' }} >إرسال</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </>
    )
}

export default FormLogic
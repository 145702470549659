import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import ContactData from '../components/Contact/ContactData/ContactData'
import FormLogic from '../components/Contact/Form/FormLogic'

const Contact = () => {
    return (
        <>
            <Box p={3} >
                <Container>
                    <Grid container spacing={4} sx={{ display: 'flex', alignItems: 'center' }} >
                        <Grid item md={4} xs={12}>
                            <ContactData />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <FormLogic />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Contact
import { Box } from '@mui/material'
import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from 'react-router-dom';
const SotialFooter = () => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-around' }}>
                <Link to='https://whas.me/o0uop6jdaX'>
                    <WhatsAppIcon sx={{ color: '#F7C42B', fontSize: '2.5rem' }} />
                </Link>
                <Link to='https://www.instagram.com/idoor_ksa'>
                    <InstagramIcon sx={{ color: '#F7C42B', fontSize: '2.5rem' }} />
                </Link>
                <Link to='https://www.facebook.com/IDoor2030/'>
                    <FacebookIcon sx={{ color: '#F7C42B', fontSize: '2.5rem' }} />
                </Link>
                <Link to='https://telegram.me/+502ZREFAKjA4MTM8'>
                    <TelegramIcon sx={{ color: '#F7C42B', fontSize: '2.5rem' }} />
                </Link>
                <Link to='https://x.com/idoork89720/status/1695351485750452404?s=46'>
                    <TwitterIcon sx={{ color: '#F7C42B', fontSize: '2.5rem' }} />
                </Link>
            </Box>
        </>
    )
}

export default SotialFooter
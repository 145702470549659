import React from 'react'
import SliderLogic from './SliderLogic/SliderLogic'

import { Box, Typography } from '@mui/material'

const WhyUs = ({ title, image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8, image_9, image_10, image_11, image_12, image_13, image_14, image_15, image_16, image_17, image_18, image_19, image_20 }) => {
    return (
        <>
            <Box p={4} >
                <Typography variant='h3' sx={{ color: '#F7C42B', fontWeight: 'bold', textAlign: 'center' }} >{title}</Typography>
                <SliderLogic
                    image_1={image_1}
                    image_3={image_3}
                    image_4={image_4}
                    image_5={image_5}
                    image_6={image_6}
                    image_7={image_7}
                    image_9={image_9}
                    image_10={image_10}
                    image_12={image_12}
                    image_13={image_13}
                    image_14={image_14}
                    image_15={image_15}
                    image_16={image_16}
                    image_17={image_17}
                    image_18={image_18}
                    image_19={image_19}
                    image_20={image_20}
                />
            </Box >
        </>
    )
}

export default WhyUs
import React from 'react'
import Header from './../components/Header/Header'
import Service from '../components/Service/Service'
import ProductsCatalog from '../components/ProductsCatalog/ProductsCatalog'
import WhyUs from '../components/WhyUs/WhyUs'
import D1 from './../assetss/door/1_-_Wenge_8701_Dlvm2Pt.jpg.400x0_q85.jpg'
// import D2 from './../assetss/door/1_-_Wenge_8701_px4BNAG.jpg.400x0_q85.jpg'
import D3 from './../assetss/door/2_-_Dark_Zebrano_7402_caf4MHJ.jpg.400x0_q85.jpg'
import D4 from './../assetss/door/4_-_Mahogany_6502_TM45_IVF0UCt.jpg.400x0_q85.jpg'
import D5 from './../assetss/door/6_-_Grey_Oak_Light_3P-10.jpg.400x0_q85.jpg'
import D6 from './../assetss/door/7_-_330305.jpg.400x0_q85.jpg'
import D7 from './../assetss/door/8_-_Beach_4303.jpg.400x0_q85.jpg'
// import D8 from './../assetss/door/009.jpg'
import D9 from './../assetss/door/9_-_Grey_Oak_Dark_193-2.jpg.400x0_q85.jpg'
import D10 from './../assetss/door/10_-_Blanco_Antico__SH213-2.jpg.400x0_q85.jpg'
// import D11 from './../assetss/door/10e6b1931c89529d17b6106055864d57.jpg'
import D12 from './../assetss/door/11_-_Wenge_horizontal_19502.jpg.400x0_q85.jpg'
import D13 from './../assetss/door/12_-_Bi_Drift_Wood_0771.jpg.400x0_q85 (1).jpg'
import D14 from './../assetss/door/13_-_Rose_Wood_9601_TM45_.jpg.400x0_q85.jpg'
import D15 from './../assetss/door/14_-_Dark_Grey_Oak_64T.jpg.400x0_q85.jpg'
import D16 from './../assetss/door/15_-_Off_White_40029_TM_101_.jpg.400x0_q85.jpg'
import D17 from './../assetss/door/16_-_Walnut_Horizontal_10202.jpg.400x0_q85.jpg'
import D18 from './../assetss/door/17_-_Zebrano_2503.jpg.400x0_q85.jpg'
import D19 from './../assetss/door/18_-_Blanco_S0102.jpg.400x0_q85.jpg'
import D20 from './../assetss/door/19_-_Drift_Wood_3371.jpg.400x0_q85.jpg'
import C1 from './../assetss/c/1.JPG'
import C2 from './../assetss/c/11 - Copy.jpg'
import C3 from './../assetss/c/2 - Copy.jpg'
import C4 from './../assetss/c/4 - Copy.jpg'
import C5 from './../assetss/c/6 - Copy.jpg'
import C6 from './../assetss/c/9 - Copy.jpg'
import C7 from './../assetss/c/بيجي 1.jpg'
import C8 from './../assetss/c/صوره 1.jpg'
import C9 from './../assetss/c/لون 10.JPG'
import C10 from './../assetss/c/لون 3 - Copy.jpg'
import C11 from './../assetss/c/لون 8 - Copy.JPG'
import C12 from './../assetss/c/لون5 - Copy.JPG'
import C13 from './../assetss/c/11.jpg'
import C14 from './../assetss/c/2.jpg'
import C15 from './../assetss/c/4.jpg'
import C16 from './../assetss/c/6.jpg'
import C17 from './../assetss/c/9.jpg'
import C18 from './../assetss/c/لون 3.jpg'
import C19 from './../assetss/c/لون 8.JPG'
import C20 from './../assetss/c/لون5.JPG'
import K1 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (1).jpeg'
import K2 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (10).jpeg'
import K3 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (11).jpeg'
import K4 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (12).jpeg'
import K5 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (13).jpeg'
import K6 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22.jpeg'
import K7 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (15).jpeg'
import K8 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (2).jpeg'
import K9 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (3).jpeg'
import K10 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (4).jpeg'
import K11 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (5).jpeg'
import K12 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (6).jpeg'
import K13 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (7).jpeg'
import K14 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (8).jpeg'
import K15 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22 (9).jpeg'
import K16 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.45.22.jpeg'
import K17 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.46.36 (1).jpeg'
import K18 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.46.36 (2).jpeg'
import K19 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.46.37 (1).jpeg'
import K20 from './../assetss/K/WhatsApp Image 2023-09-04 at 14.46.37 (2).jpeg'
// import SocialContact from '../components/UI/SocialContact'
const Home = () => {
    return (
        <>
            <Header />

            {/* <SocialContact /> */}
            <Service />
            <ProductsCatalog />
            <WhyUs title={'إختار بابك'}
                image_1={D1}
                // image_2={D2}
                image_3={D3}
                image_4={D4}
                image_5={D5}
                image_6={D6}
                image_7={D7}
                // image_8={D8}
                image_9={D9}
                image_10={D10}
                // image_11={D11}
                image_12={D12}
                image_13={D13}
                image_14={D14}
                image_15={D15}
                image_16={D16}
                image_17={D17}
                image_18={D18}
                image_19={D19}
                image_20={D20}
            />
            <WhyUs title={'الالوان'}
                image_1={C1}
                image_2={C2}
                image_3={C3}
                image_4={C4}
                image_5={C5}
                image_6={C6}
                image_7={C7}
                image_8={C8}
                image_9={C9}
                image_10={C10}
                image_11={C11}
                image_12={C12}
                image_13={C13}
                image_14={C14}
                image_15={C15}
                image_16={C16}
                image_17={C17}
                image_18={C18}
                image_19={C19}
                image_20={C20}
            />
            <WhyUs title={'اكسسوارات الابواب'}
                image_1={K1}
                image_2={K2}
                image_3={K3}
                image_4={K4}
                image_5={K5}
                image_6={K6}
                image_7={K7}
                image_8={K8}
                image_9={K9}
                image_10={K10}
                image_11={K11}
                image_12={K12}
                image_13={K13}
                image_14={K14}
                image_15={K15}
                image_16={K16}
                image_17={K17}
                image_18={K18}
                image_19={K19}
                image_20={K20}
            />
        </>
    )
}

export default Home
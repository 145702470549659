import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import './Header.css'
import { useNavigate } from 'react-router-dom'
const Header = () => {
    const navigate = useNavigate()
    return (
        <Box className='header'>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'end',
                gap: 3,
                margin: '15px'
            }}>
                <Typography variant='h3' sx={{ color: '#FFF', fontSize: { md: '4rem', xs: '2.5rem' } }} >مصنع أي دور I’DOOR متخصصون في</Typography>
                <Typography variant='h3' sx={{ color: '#F7C42B', fontSize: { md: '4rem', xs: '2.5rem' } }} > الأبواب الداخلية من مادة W.P.C</Typography>
                <Button sx={{ background: '#F7C42B', fontSize: '1.875rem', "&:hover": { backgroundColor: '#F7C42B' } }}
                    onClick={() => navigate('/catalog')}
                >تصفح الكتالوج</Button>
            </Box>
        </Box>
    )
}

export default Header
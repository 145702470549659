import { CssBaseline } from '@mui/material';
import './App.css';
import NavBar from './components/NavBar'
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Catalog from './pages/Catalog';
import Contact from './pages/Contact';
import Footer from './components/Footer/Footer';
import { useEffect } from 'react';
import SotialFooter from './components/glopal/SotialFooter';
import ScrollTo from './components/UI/ScrollToTop';
// import SocialContact from './components/UI/SocialContact';

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      <SotialFooter />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="catalog" element={<Catalog />} />
        <Route path="contact-us" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

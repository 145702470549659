import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ServiceCard from './ServiceCard'
import ServiceOne from '../../assetss/Service/accuracy (2) 1.png'
import ServiceTow from '../../assetss/Service/unboxing 1.png'
import ServiceThree from '../../assetss/Service/leader (1) 1.png'
const Service = () => {
    return (
        <Box p={3} >
            <Container>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 3, padding: 3, textAlign: 'center' }}>
                    <Typography variant='h3' sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", fontSize: { md: '5rem', xs: '2.5rem' } }} >
                        لماذا تتعامل مع <span style={{ color: '#F7C42B' }}>I’DOOR ؟</span>
                    </Typography>
                    <Typography sx={{ fontSize: { md: '2rem', xs: '1.5rem' } }} >
                        استخدامك لأبواب I’DOOR هيساعدك تضيف طابع مميز خاص بيك وتظهر بشكل فريد فهي دائما أكثر من المتوقع
                    </Typography>
                </Box>
                <Grid container sx={{ gap: { md: '0', xs: '16px' } }} >
                    <Grid item md={4} xs={12}>
                        <ServiceCard
                            imgSrc={ServiceOne}
                            title={`الدقة في العمل`}
                            desc={`نحن ننتج وفقا للمعايير لضمان المواد المستخدمة ومواصفات المنتجات النهائية`} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <ServiceCard
                            imgSrc={ServiceTow}
                            title={`منتجات صديقة`}
                            desc={` اعتمدنا في صناعتنا ، وإعادة تدوير المواد الأساسية لأننا نشعر أننا مسؤولون عن الحفاظ على البيئة للأجيال القادمة`} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <ServiceCard
                            imgSrc={ServiceThree}
                            title={`نحن خبراء`}
                            desc={`نحن خبراء في ما نقوم به خاصة في تصنيع الأبواب والديكورات الداخلية ونثق بفريقنا المحترف.`} />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 3, padding: 3, textAlign: 'center' }}>
                    <Typography variant='h3' sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", fontSize: { md: '5rem', xs: '2.5rem' } }} >
                        مميزات<span style={{ color: '#F7C42B' }}> wpc</span>
                    </Typography>
                    <Typography sx={{ fontSize: { md: '2rem', xs: '1.5rem' } }} >
                        ضد الماء.
                        مقاوم للخدوش والصدمات.
                        ضد النمل الأبيض والحشرات.
                        عازل للصوت بنسبة عالية.
                        مقاوم للتعفن والانشقاق.
                        صديقة للبيئة ولا تحتوي مواد سامة.
                        لا تتطلب صبغ بشكل دوري.
                        مقاوم للتغيرات المناخية  ودرجات الحرارة العالية.
                        جودة ومتانة المنتج بقوة أكبر من الخشب الطبيعي.
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Service